import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import config from "../../../../config";
import { Dialog } from "primereact/dialog";
import EditAddForm from "../employee/edit-add-form";
import { toast } from "react-toastify";
import Axios from "axios";
import editIcon from "../../../../assets/assests/edit.png";
import { useNavigate } from "react-router-dom";
import eyeIcon from "../../../../assets/assests/eye.png";
import Spinner from "../../components/spinner";
import { format } from "date-fns";
import { Tag } from "primereact/tag";

export default function EmployeeScreen() {
  const menu = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [employeeDateRange, setEmployeeDateRange] = useState(null);
  const [editable, setEditable] = useState();
  const [res, setRes] = useState();
  const [selectedData, setSelectedData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [rowselect, setRowselect] = useState(null);
  const [type, setType] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selecteCurrentPost, setSelectedVertical] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const navigate = useNavigate();
  const formatDate = (date) => format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  const fetchFilters = async () => {
    try {
      const token = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers,
      };

      const [departmentResponse, designationResponse] = await Promise.all([
        fetch(`${config.baseUrl}api/Department/GetAll`, options),
        fetch(`${config.baseUrl}api/Posts/GetAll`, options),
      ]);

      if (!departmentResponse.ok || !designationResponse.ok) {
        throw new Error("Error fetching filter data");
      }
      const departmentsData = await departmentResponse.json();
      const filteredDep = departmentsData?.data.map((department) => ({
        value: department.id,
        label: department.name,
      }));
      setDepartments(filteredDep);

      const designationsData = await designationResponse.json();

      setDesignations(designationsData?.data);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  useEffect(() => {
    fetchFilters();
    GetEmployee();
  }, []);

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setShowDialog(true);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const roleOptions = [{ name: "Admin", value: 1 }];

  const GetEmployee = async (filterPayload = {}) => {
    const token = localStorage.getItem("authToken");
    const getCurrentMonthDates = () => {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return {
        fromDate: formatDate(startOfMonth),
        toDate: formatDate(endOfMonth),
      };
    };

    // Use the default current month if fromDate or toDate is empty or null
    const { fromDate, toDate } =
      !filterPayload?.fromDate || !filterPayload?.toDate
        ? getCurrentMonthDates()
        : { fromDate: filterPayload?.fromDate, toDate: filterPayload?.toDate };

    // Send 0 if designationId or departmentId is null
    const requestBody = {
      currentPost: filterPayload?.designationId?.id || 0,
      department: filterPayload?.departmentId || 0,
      status: filterPayload?.status || "", // Use the status from filterPayload
      fromDate,
      toDate,
    };
    setLoading(true);
    try {
      const response = await fetch(
        `${config.baseUrl}api/Employee/GetAllEmployees`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      setEmployeeList(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const statusValue = [
    { name: "Active", value: "Active" },
    { name: "InActive", value: "InActive" },
];



  const handleFilterApply = () => {
    const filterPayload = {
      designationId: selectedDesignation || null, 
      departmentId: selectedDepartment || null,
      status: selectedStatus|| "", 
      fromDate:
        employeeDateRange && employeeDateRange[0]
          ? formatDate(employeeDateRange[0])
          : null,
      toDate:
        employeeDateRange && employeeDateRange[1]
          ? formatDate(employeeDateRange[1])
          : null,
    };
    GetEmployee(filterPayload);
  };

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  // Reset filters
  const handleResetFilters = (e) => {
    setSelectedVertical(null);
    setSelectedDepartment(null);
    setSelectedDesignation(null);
    setSelectedStatus(null)
    setEmployeeDateRange(null);
    menu.current.toggle(e);
  };

  // Filter menu items, dynamically populated from API
  const menuItems = [
    {
      items: [
        {
          template: () => (
            <div className="p-field p-fluid filters-label">
              <p>Filters</p>
              <i
                className="pi pi-times"
                onClick={() => {
                  setSelectedDepartment(null);
                  setSelectedDesignation(null);
                  setSelectedStatus(null)
                }}
              ></i>
            </div>
          ),
        },
        {
          label: "Department",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedDepartment}
                options={departments}
                onChange={(e) => setSelectedDepartment(e.value)}
                placeholder="Select Department"
                appendTo="self"
                loading={loading}
              />
            </div>
          ),
        },
        {
          label: "Designation",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedDesignation}
                options={designations}
                onChange={(e) => setSelectedDesignation(e.value)}
                optionLabel="name"
                placeholder="Select Designation"
                appendTo="self"
              />
            </div>
          ),
        },

        {
          label: "Status",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedStatus}
                options={statusValue}
                onChange={(e) => setSelectedStatus(e.value)}
                optionLabel="name"
                placeholder="Select Status"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Actions",
          template: () => (
            <div
              className="p-field p-fluid mt-4"
              style={{ display: "flex", gap: "10px" }}
            >
              <Button
                label="Cancel"
                className="p-button-secondary"
                onClick={(e) => {
                  handleResetFilters(e);
                }}
              />
              <Button
                label="Apply Filter"
                className="p-button-primary"
                onClick={handleFilterApply}
              />
            </div>
          ),
        },
      ],
    },
  ];

  const updateEmployeeAction = async (rowData) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);

      const response = await Axios.post(
        `${config.baseUrl}api/Employee/ToggleEmployeeActiveInactive/${rowData.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data.message);

      // Handle success
      GetEmployee();
      setShowDialog(false);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        <div
          className={`toggle-switch ${
            rowData.isActive ? "active" : "inactive"
          }`}
          onClick={() => updateEmployeeAction(rowData)}
        >
          <div
            className={`toggle-knob ${
              rowData.isActive ? "active" : "inactive"
            }`}
          />
        </div>
        <Button
          className="p-button-rounded p-button-edit p-0 mr-3 action-images"
          onClick={() => {
            navigate(`/employee/details/${rowData.id}`, {
              state: { employee: rowData },
            });
          }}
          aria-label="view"
        >
          <img src={eyeIcon} alt="Eye" />
        </Button>
        <Button
          className=" p-button-edit p-0 mr-3"
          onClick={() => {
            toggleDialogMode(true);
            editAction(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
      </div>
    );
  };

  // const handleDetailsClick = (rowData) => {
  //   navigate(`/employee/details/${rowData.id}`, {
  //     state: { employee: rowData },
  //   });
  // };
  // const rowClassName = "p-datatable-clickable";

  return (
    <>
      <div className="grid align-items-center mb-3">
        <Dialog
          header={
            <div className="popup-header">
              <div className="popup-header-text">
                {isEdit ? "Edit Employee" : "Add Employee"}
              </div>
            </div>
          }
          visible={showDialog}
          onHide={onHide}
          className="dialog-size"
        >
          <EditAddForm
            rowData={rowselect}
            GetEmployee={GetEmployee}
            setShowDialog={setShowDialog}
            editable={editable}
            onHide={onHide}
            formData={isEdit ? selectedData : null}
            setRes={setRes}
          />
        </Dialog>
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Employee Management</h5>
        </div>
        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Menu model={menuItems} popup ref={menu} className="filter-menu" />
          <Button
            label="Filters"
            icon="pi pi-sliders-h"
            className="p-button ml-3 filters-btn"
            onClick={(e) => menu.current.toggle(e)}
          />
          <Button
            label="Add Employee"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setShowDialog(true);
            }}
            className="p-button ml-3 mt-2"
          />
        </div>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}
      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={employeeList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          // onRowClick={(event) => handleDetailsClick(event.data)}
          // rowClassName={rowClassName}
          globalFilter={globalFilter}
        >
          <Column field="employeeNumber" header="ID" />
          <Column field="employeeName" header="Name" />
          <Column field="phoneNumber" header="Phone" />
          <Column field="cnic" header="CNIC" />
          <Column field="role" header="role" />

          <Column
            field="currentPostId"
            header="Designation"
            body={(rowData) => {
              const post = designations?.find(
                (option) => option.id === rowData.currentPostId
              );
              return post ? post.name : "N/A";
            }}
          />
          <Column
            field="departmentId"
            header="Department"
            body={(rowData) => {
              const department = departments?.find(
                (dept) => dept.value === rowData.departmentId
              );
              return department ? department.label : "";
            }}
          />
          <Column field="religion" header="Religion"     body={(rowData) => rowData.religion ? rowData.religion : "N/A"} 
 />
          <Column field="email" header="Email" />
          <Column
            field="isActive"
            header="Status"
            body={(rowData) =>
              rowData.isActive ? (
                <Tag className="custom-success-tag" value="Active" />
              ) : (
                <Tag className="custom-danger-tag" value="In-Active" />
              )
            }
          />
          <Column field="" body={actionTemplate} header="Action" />
        </DataTable>
      </div>
    </>
  );
}

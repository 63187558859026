import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ActionButtons from "../../components/action_buttons";
import { toast } from "react-toastify"; // Assume you're using toast for notifications
import { useNavigate } from "react-router-dom";
import config from "../../../../config";
import Spinner from "../../components/spinner";
const EditAddForm = ({
  onHide,
  editable,
  rowData,
  setShowDialog,
  GetShiftData,
  assignCheck,
}) => {
  const navigate = useNavigate();
  const [selectedShiftDays, setSelectedShiftDays] = useState(
    rowData?.shiftDays?.split(",") || []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [assignedEmployee, setAssignedEmployee] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const groupedShiftDays = [
    {
      label: "Weekdays",
      items: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
      ],
    },
    {
      label: "Weekends",
      items: [
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
    },
  ];

  const shiftOptions = [
    { label: "Morning", value: "Morning" },
    { label: "Evening", value: "Evening" },
    { label: "Night", value: "Night" },
  ];

  const validationSchema = Yup.object({
    shiftName: Yup.string().required("Shift Name is required"),
    shiftInChargeId: Yup.string().required("Shift Incharge is required"),
    
    startTime: Yup.date()
      .typeError("Start Time must be a valid time")
      .required("Start Time is required"),

    endTime: Yup.date()
      .typeError("End Time must be a valid time")
      .required("End Time is required"),
    workingHours: Yup.number().required("Working Hours are required"),
    shiftDays: Yup.array().min(1, "Select at least one shift day"),
  });

  const GetEmployee = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/Employee/GetEmployeesNameAndId`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const result = await response.json();
      const employees = result?.data?.map((employee) => ({
        label: employee?.name,
        value: employee?.id,
      }));
      
      console.log("employees:", employees); // Log to check if data is mapped correctly
      setEmployeeList(employees);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetEmployee();
  }, []);

  const GetShiftEmployees = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/Shift/GetShiftEmployees/${rowData?.id}`,
        {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(filters),
        }
      );

      const data = await response.json();
      setAssignedEmployee(data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const setEmployees = async () => {
      if (editable) {
        await GetShiftEmployees(); // Fetch the employee list from the API or source
        const employees = assignedEmployee?.employees || [];
        const selectedEmployees = employees.map((employeeId) => {
          const foundEmployee = employeeList.find(
            (emp) => emp.value == employeeId
          );

          return foundEmployee
            ? { label: foundEmployee.label, value: foundEmployee.value }
            : { label: employeeId, value: employeeId };
        });

        setSelectedEmployees(selectedEmployees);
        setAssignModal(true);
      }
    };

    setEmployees();
  }, [editable, rowData, employeeList]);

  const parseTime = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");

    hours = String(hours);
    if (hours === "12") {
      hours = "00"; // handle 12 AM case
    }
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }

    return new Date(`1970-01-01T${hours.padStart(2, "0")}:${minutes}:00`);
  };

  const formik = useFormik({
    initialValues: {
      shiftName: rowData?.shiftName || "",
      shiftInChargeId: "",
      startTime: rowData?.startingTime
        ? new Date(
            `1970-01-01T${
              parseTime(rowData.startingTime).toISOString().split("T")[1]
            }`
          )
        : null,
      endTime: rowData?.endingTime
        ? new Date(
            `1970-01-01T${
              parseTime(rowData.endingTime).toISOString().split("T")[1]
            }`
          )
        : null,
      workingHours: rowData?.hours || "",
      shiftDays: selectedShiftDays || [],
      ...(editable ? { id: rowData?.id } : {}),
    },
    validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("authToken");

      const formattedValues = {
        ...values,
        shiftDays: values.shiftDays.join(","), // Convert shiftDays to a comma-separated string
        workingHours: parseFloat(values.workingHours), // Ensure workingHours is a number
        startTime: values.startTime
          ? values.startTime.toLocaleTimeString("en-GB", { hour12: false })
          : null, // Format time
        endTime: values.endTime
          ? values.endTime.toLocaleTimeString("en-GB", { hour12: false })
          : null, // Format time
      };

      try {
        let shiftId;

        if (editable) {
          // Update shift
          const updateResponse = await axios.put(
            `${config.baseUrl}api/Shift/UpdateShift`,
            formattedValues,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          shiftId = rowData?.id; // Use the shiftId from the existing rowData since it's an update
          if (!assignCheck) toast.success("Shift updated successfully");

          // Update assigned employees
          if (selectedEmployees?.length > 0 && shiftId) {
            const assignPayload = {
              shiftId,
              employeeIds: selectedEmployees?.map((employee) => employee.value), // Map to extract employee values (IDs)
            };

            await axios.put(
              `${config.baseUrl}api/Shift/UpdateAssignedShift`,
              assignPayload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );

            toast.success("Shift assigned to employees successfully");
          }
        } else {
          // Create shift
          const createResponse = await axios.post(
            `${config.baseUrl}api/Shift/AddShift`,
            formattedValues,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          shiftId = createResponse.data.shiftId; // Use the shiftId from the create response
          toast.success("Shift added successfully");

          // Assign employees to the newly created shift
          if (selectedEmployees?.length > 0 && shiftId) {
            const assignPayload = {
              shiftId,
              employeeIds: selectedEmployees?.map((employee) => employee.value), // Map to extract employee values (IDs)
            };

            await axios.post(
              `${config.baseUrl}api/Shift/AssignShift`,
              assignPayload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );

            toast.success("Shift assigned to employees successfully");
          }
        }

        GetShiftData();
        onHide(); // Close the form
      } catch (error) {
        // Check if the error response exists and display the message
        const errorMessage =
          error.response?.data?.message ||
          "Something went wrong. Please try again.";
        toast.error(errorMessage);
      }
    },
  });

  useEffect(() => {
    if (employeeList?.length && rowData?.shiftIncharge) {
      const shiftInChargeId =
        employeeList.find(
          (emp) =>
            emp.label.toLowerCase().trim() ===
            rowData.shiftIncharge.toLowerCase().trim()
        )?.value || "";

      // Update the Formik field value for shiftInChargeId
      formik.setFieldValue("shiftInChargeId", shiftInChargeId);
    }
  }, [employeeList, rowData]); // Trigger when employeeList or rowData changes

  const handleAssignUser = () => {
    setAssignModal(!assignModal);
    setSelectedEmployees([]);
  };

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const filteredEmployees = employeeList?.filter((employee) =>
    employee?.label.toLowerCase().includes(globalFilter?.toLowerCase())
  );

  const addEmployee = (employee) => {
    // Check if the employee is already selected
    if (!selectedEmployees?.some((e) => e.value === employee.value)) {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  };

  // Function to remove an employee
  const removeEmployee = (employee) => {
    // Filter out the employee to be removed
    setSelectedEmployees(
      selectedEmployees?.filter((e) => e.value !== employee.value)
    );
  };

  return (
    <>
      <div className="main-form ">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            {!assignCheck && (
              <>
                <div className="field col-12 md:col-4">
                  <label htmlFor="shift">
                    Shift Name
                    <span className="Staric-Custom text-danger"> *</span>
                  </label>
                  <Dropdown
                    id="shift"
                    options={shiftOptions}
                    value={formik.values.shiftName}
                    onChange={(e) => formik.setFieldValue("shiftName", e.value)}
                    placeholder="Select a Shift"
                  />
                  {formik.touched.shiftName && formik.errors.shiftName && (
                    <small className="p-error">{formik.errors.shiftName}</small>
                  )}
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="shift">
                    Shift Incharge
                    <span className="Staric-Custom text-danger"> *</span>
                  </label>
                  <Dropdown
                    id="shiftInChargeId"
                    options={employeeList}
                    value={formik.values.shiftInChargeId}
                    onChange={(e) =>
                      formik.setFieldValue("shiftInChargeId", e.value)
                    }
                    placeholder="Select a Shift"
                    loading={loading}
                  />
                  {formik.touched.shiftInChargeId &&
                    formik.errors.shiftInChargeId && (
                      <small className="p-error">
                        {formik.errors.shiftInChargeId}
                      </small>
                    )}
                </div>
                <div className="field col-12 md:col-4 pt-0">
                  <label htmlFor="shiftDays">
                    Shift Days
                    <span className="Staric-Custom text-danger"> *</span>
                  </label>
                  <MultiSelect
                    value={formik.values.shiftDays}
                    options={groupedShiftDays}
                    onChange={(e) => formik.setFieldValue("shiftDays", e.value)}
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    placeholder="Select Shift Days"
                    display="chip"
                  />
                  {formik.touched.shiftDays && formik.errors.shiftDays && (
                    <small className="p-error">{formik.errors.shiftDays}</small>
                  )}
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="startTime">
                    Starting Time
                    <span className="Staric-Custom text-danger"> *</span>
                  </label>
                  <Calendar
                    id="startTime"
                    value={formik.values.startTime}
                    onChange={(e) => formik.setFieldValue("startTime", e.value)}
                    showIcon
                    hourFormat="12"
                    timeOnly
                    placeholder="Enter Starting Time"
                  />
                  {formik.touched.startTime && formik.errors.startTime && (
                    <small className="p-error">{formik.errors.startTime}</small>
                  )}
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="endTime">
                    Ending Time
                    <span className="Staric-Custom text-danger"> *</span>
                  </label>
                  <Calendar
                    id="endTime"
                    value={formik.values.endTime}
                    onChange={(e) => formik.setFieldValue("endTime", e.value)}
                    showIcon
                    hourFormat="12"
                    timeOnly
                    placeholder="Enter Ending Time"
                  />
                  {formik.touched.endTime && formik.errors.endTime && (
                    <small className="p-error">{formik.errors.endTime}</small>
                  )}
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="workingHours">
                    Working Hours
                    <span className="Staric-Custom text-danger"> *</span>
                  </label>
                  <InputText
                    id="workingHours"
                    value={formik.values.workingHours}
                    onChange={formik.handleChange}
                    placeholder="Enter Working Hours"
                  />
                  {formik.touched.workingHours &&
                    formik.errors.workingHours && (
                      <small className="p-error">
                        {formik.errors.workingHours}
                      </small>
                    )}
                </div>
              </>
            )}

            {!assignCheck ? (
              <div className="field col-12 md:col-12">
                <label
                  htmlFor="workingHours"
                  className="assign"
                  onClick={handleAssignUser}
                >
                  {assignModal ? <span>- </span> : <span>+ </span>}
                  Assign User
                </label>
              </div>
            ) : null}

            {assignModal && (
              <div className="field col-12 md:col-12">
                <div className="assign-section md:col-12 flex justify-content-between ">
                  <div className="assign-left ">
                    {loading && (
                      <div className="spinner-overlay">
                        <Spinner />
                      </div>
                    )}
                    <span className="p-input-icon-left fixed-search">
                      <i className="pi pi-search" />
                      <InputText
                        className="mbl_view"
                        placeholder="Search"
                        value={globalFilter} // Bind input value to state
                        onChange={handleGlobalFilterChange}
                      />
                    </span>
                    <div className="employee-list text-base">
                      {filteredEmployees?.map((employee, index) => {
                        const isAdded = selectedEmployees?.some(
                          (selected) => selected.value === employee.value
                        );
                        return (
                          <div
                            key={employee.value}
                            className="employee-item text-base"
                          >
                            <span>{employee.label}</span>
                            <div>
                              <div className="assign-buttons">
                                <button
                                  className={`tag-button ${
                                    isAdded ? "disabled-btn" : "add-btn"
                                  }`}
                                  type="button"
                                  onClick={() => addEmployee(employee)}
                                  disabled={isAdded} // Disable button if employee is already added
                                >
                                  <i
                                    className="pi pi-plus"
                                    style={{
                                      marginRight: "0.5rem",
                                      fontSize: "8px",
                                      fontWeight: "600",
                                    }}
                                  ></i>{" "}
                                  Add
                                </button>

                                {/* Remove Button */}
                                {isAdded && (
                                  <button
                                    className="tag-button-sec"
                                    type="button"
                                    onClick={() => removeEmployee(employee)}
                                  >
                                    <i
                                      className="pi pi-times"
                                      style={{
                                        marginRight: "0.5rem",
                                        fontSize: "8px",
                                        fontWeight: "600",
                                      }}
                                    ></i>
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="assign-right text-base">
                    {selectedEmployees?.map((employee) => (
                      <div
                        key={employee.value}
                        className="employee-tag text-base"
                      >
                        <span>{employee.label}</span>
                        <button
                          className="assign-right-btn"
                          onClick={() => removeEmployee(employee)}
                        >
                          <i
                            className="pi pi-times"
                            style={{
                              marginRight: "0.5rem",
                              fontSize: "8px",
                              fontWeight: "600",
                            }}
                          ></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="p mt-4 form-buttons">
            <ActionButtons
              loading={loading}
              onCancel={onHide}
              onSave={formik.handleSubmit}
              saveLabel={editable ? "Update Changes" : "Save Changes"}
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;

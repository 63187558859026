import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import tick from "../../../../assets/assests/tick-success.png";
import config from "../../../../config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditAddForm = ({
  onHide,
  editable,
  setRes,
  rowData,
  setShowDialog,
  GetLeaveData,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [apiRes, setApiRes] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [leaveOptions, setLeaveOptions] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const [showDialog, setShowDialog] = useState(false);

  const validationSchema = Yup.object({
    employeeId: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
    leaveTypeId: Yup.string().required("Required"),
    reason: Yup.string().required("Required"),
    startDate: Yup.date()
      .required("Start Date is required")
      .nullable()
      .min(new Date(), "Start Date cannot be earlier than today"), // Validate against current date
    endDate: Yup.date()
      .required("End Date is required")
      .nullable()
      .min(Yup.ref("startDate"), "End Date must be later than Start Date"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,

    initialValues: {
      employeeId: "",
      endDate: "",
      leaveTypeId: "",
      reason: "",
      role: localStorage.getItem("role") || "",
      startDate: "",
      userId: localStorage.getItem("userId") || "",
      // id: formData.id || '',
    },
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);
      try {
        let response;
        if (editable) {
          response = await axios.put(
            `${config.baseUrl}api/Leaves/UpdateLeaveRequest`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          toast.success("Updated Successfully");
        } else {
          response = await axios.post(
            `${config.baseUrl}api/Leaves/AddLeaveRequest`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          toast.success("Added Successfully");
        }
        setLoading(false);
        setApiRes(response);
        setShowConfirmDialog(true);
        GetLeaveData();
        setRes(response);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Something went wrong";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });
  const id = localStorage.getItem("userId");

  const GetEmployee = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/Employee/GetEmployeesNameAndId`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const result = await response.json();
      const employees = result?.data?.map((employee) => ({
        label: employee?.name,
        value: employee?.id,
      }));
      
      console.log("employees:", employees); // Log to check if data is mapped correctly
      setEmployeeList(employees);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetELeaveType = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(`${config.baseUrl}api/LeaveType/GetAll`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      setLeaveOptions(data?.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetELeaveType();
    GetEmployee();
  }, []);

  useEffect(() => {
    if (editable && rowData) {
      formik.setFieldValue('employeeId', rowData.employeeId || null);
      formik.setFieldValue('reason', rowData.reason);
      formik.setFieldValue('leaveTypeId', rowData.leaveTypeId || "");
      formik.setFieldValue('startDate', new Date(rowData.from));
      formik.setFieldValue('endDate', new Date(rowData.to));
    }
  }, [editable, rowData]);

  console.log("leaveOptions==", leaveOptions)
  const handleRedirect = () => {
    setShowDialog(false);
  };

  const dialogFooter = (
    <div>
      <Button
        label="Okay, Great!"
        style={{
          margin: "auto !important",
          display: "flex",
          marginBottom: "20px",
          justifyContent: "center",
          width: "100%",
        }}
        icon="pi pi-check"
        onClick={handleRedirect}
      />
    </div>
  );

  return (
    <>
      <ToastContainer />
      <ConfirmDialog
        className="dialog-header-hidden"
        visible={showConfirmDialog}
        footer={dialogFooter}
        message={
          <>
            <br />
            <img src={tick} style={{ margin: "auto", display: "flex" }} />
            <br />
            <div
              style={{
                textAlign: "center",
                lineHeight: "1.5",
                fontWeight: "600",
              }}
            >
              Request of leave has been <br />
              successfully sent to HOD.
            </div>
            <br />
          </>
        }
      />
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-3">
              <label htmlFor="leaveType">
                Employee Name{" "}
                <span className="Staric-Custom text-danger"> *</span>
              </label>
              <Dropdown
                id="employeeId"
                name="employeeId"
                value={formik.values.employeeId}
                options={employeeList}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Select Employee"
                loading={loading}
              />
              {formik.touched.employeeId && formik.errors.employeeId && (
                <small className="p-error">{formik.errors.employeeId}</small>
              )}
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="leaveType">
                Leave Type <span className="Staric-Custom text-danger"> *</span>
              </label>
              <Dropdown
                id="leaveTypeId"
                name="leaveTypeId"
                value={formik.values.leaveTypeId}
                options={leaveOptions}
                optionLabel="name"
                optionValue="id"
                placeholder="Select leave type"
                onChange={formik.handleChange}
                loading={loading}
              />
              {formik.touched.leaveTypeId && formik.errors.leaveTypeId ? (
                <div className="error">{formik.errors.leaveTypeId}</div>
              ) : null}
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="from">
                Leave Require From{" "}
                <span className="Staric-Custom text-danger"> *</span>
              </label>
              <Calendar
                id="startDate"
                placeholder="Enter"
                showIcon
                name="startDate"
                type="text"
                value={formik.values.startDate}
                minDate={new Date()}
                onChange={formik.handleChange}
              />
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="error">{formik.errors.startDate}</div>
              ) : null}
            </div>

            <div className="field col-12 md:col-3">
              <label htmlFor="to">
                To <span className="Staric-Custom text-danger"> *</span>
              </label>
              <Calendar
                id="endDate"
                placeholder="Enter"
                showIcon
                name="endDate"
                type="text"
                value={formik.values.endDate}
                minDate={new Date()}
                onChange={formik.handleChange}
              />
              {formik.touched.endDate && formik.errors.endDate ? (
                <div className="error">{formik.errors.endDate}</div>
              ) : null}
            </div>

            <div className="field col-12 md:col-12">
              <label htmlFor="purpose">
                Purpose/Reason{" "}
                <span className="Staric-Custom text-danger"> *</span>
              </label>
              <InputTextarea
                id="reason"
                placeholder="Enter"
                showIcon
                name="reason"
                type="text"
                value={formik.values.reason}
                onChange={formik.handleChange}
                rows={4}
                cols={10}
              />
              {formik.touched.reason && formik.errors.reason ? (
                <div className="error">{formik.errors.reason}</div>
              ) : null}
            </div>
          </div>

          <div className="p mt-4 form-buttons">
            <ActionButtons
              loading={loading}
              onCancel={onHide}
              onSave={formik.handleSubmit}
              saveLabel={editable ? "Update Changes" : "Save Changes"}
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;

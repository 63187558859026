import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LoginScreen from "./app/features/screens/auth/loginscreen";
import DashboardLayout from "./layoutes/layout";
import DashboardScreen from "./app/features/screens/dashboard/dashboard";
import EmployeeScreen from "./app/features/screens/employee/employee";
import AttendanceScreen from "./app/features/screens/attendance/attendance";
import LeaveScreen from "./app/features/screens/leave/leave";
import ShiftScreen from "./app/features/screens/shift/shift";
import SettingsScreen from "./app/features/screens/settings/settings";
import EmployeeDetails from "./app/features/screens/employee/details/details";
import LeaveDetails from "./app/features/screens/leave/details";
import Designation from "./app/features/screens/designation/designation";
import Department from "./app/features/screens/department/department";
import LeaveType from "./app/features/screens/leaveType/leaveType";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/login.scss";
import "./styles/dashboard.scss";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate(); 

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setIsLoggedIn(true);
    } else {
      navigate("/login"); // Navigate to login if no token
    }
  }, [navigate]); 
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setIsLoggedIn(true);
    }
  }, []); 

  const handleLogin = (token) => {
    setIsLoggedIn(true);
    localStorage.setItem("authToken", token);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("authToken");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    toast.success("Logged out successfully.");
  };

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/login"
          element={<LoginScreen handleLogin={handleLogin} isLoggedIn={isLoggedIn} />}
        />
        {isLoggedIn && (
          <>
            <Route
              path="/"
              element={
                <DashboardLayout
                  isLoggedIn={isLoggedIn}
                  handleLogout={handleLogout}
                />
              }
            >
              <Route path="/" element={<DashboardScreen />} />
              <Route path="/employee" element={<EmployeeScreen />} />
              <Route path="/employee/details/:id" element={<EmployeeDetails />} />
              <Route path="/attendance" element={<AttendanceScreen />} />
              <Route path="/leave" element={<LeaveScreen />} />
              <Route path="/leaveType" element={<LeaveType />} />
              <Route path="/leave/details/:id" element={<LeaveDetails />} />
              <Route path="/shift" element={<ShiftScreen />} />
              <Route path="/department" element={<Department />} />
              <Route path="/designation" element={<Designation />} />
              <Route path="/settings" element={<SettingsScreen />} />
            </Route>
          </>
        ) }
      </Routes>
    </>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function LineChart({ attendanceDetailByperiod }) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        // Sort the data to ensure proper order of weeks and months
        const sortedData = attendanceDetailByperiod?.sort((a, b) => new Date(a.x) - new Date(b.x));

        // Mapping the API response
        const labels = sortedData?.map(item => item.x.replace("From date ", ""));
        const dataPoints = sortedData?.map(item => item.y);

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Attendance',
                    data: dataPoints,
                    fill: false,
                    borderColor: '#008578', // Set the line color to red
                    backgroundColor: '#008578', // Set the point color to red
                    pointBackgroundColor: '#008578', // Set the points' background color to red
                    tension: 0.4,
                },
            ],
        };

        // Chart options with Y-axis percentage formatting
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    display: false, 
                    labels: {
                        color: textColor,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const value = context.raw * 100; // Convert to percentage
                            return `${context.dataset.label}: ${value.toFixed(2)}%`;
                        },
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        autoSkip: true,
                        maxTicksLimit: 10,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        callback: function (value) {
                            return `${(value * 100).toFixed(0)}%`; // Convert to percentage
                        },
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    beginAtZero: true,
                },
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, [attendanceDetailByperiod]);

    return (
        <div style={{ position: 'relative', width: '100%', height: '400px' }}>
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    );
}

import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { Calendar } from "primereact/calendar";
import config from "../../../../config";
import { Tag } from "primereact/tag";
import { format, startOfMonth, endOfMonth } from "date-fns";
import EditAddForm from "./edit-add-form";
import { Dialog } from "primereact/dialog";
import ViewAttendanceScreen from "./view-attendance";
import Spinner from "../../components/spinner";
import eyeIcon from "../../../../assets/assests/eye.png";

export default function AttendanceScreen() {
  const menu = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const formatDate = (date) => format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  const [editable, setEditable] = useState();
  const getDefaultDateRange = () => {
    const fromDate = startOfMonth(new Date());
    const toDate = endOfMonth(new Date());
    return { fromDate: formatDate(fromDate), toDate: formatDate(toDate) };
  };
  const [res, setRes] = useState();
  const [dialogDetails, setDialogDeatils] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [deptAttendance, setDeptAttendance] = useState([]);
  const [departments, setDepartments] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [attendance, setAttendance] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [employeeDateRange, setEmployeeDateRange] = useState(null);
  const [departmentDateRange, setDepartmentDateRange] = useState(null);
  const [designations, setDesignations] = useState([]);
  const [rowselect, setRowselect] = useState(null);
  const [attendanceDetails, setAttendanceDetails] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [viewAttendanceDateRange, setViewAttendanceDateRange] = useState(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ); // Current month ka pehla din
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ); // Current month ka aakhri din
    return [firstDayOfMonth, lastDayOfMonth];
  });
  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const GetEmployeeAttendance = async (filterPayload) => {
    const token = localStorage.getItem("authToken");

    // Helper function to get the start and end date of the current month
    const getCurrentMonthDates = () => {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return {
        fromDate: formatDate(startOfMonth),
        toDate: formatDate(endOfMonth),
      };
    };

    // Use the default current month if fromDate or toDate is empty or null
    const { fromDate, toDate } =
      !filterPayload?.fromDate || !filterPayload?.toDate
        ? getCurrentMonthDates()
        : { fromDate: filterPayload?.fromDate, toDate: filterPayload?.toDate };

    // Send 0 if designationId or departmentId is null
    const requestBody = {
      designationId: filterPayload?.designationId?.id || 0,
      departmentId: filterPayload?.departmentId || 0,
      status: filterPayload?.status || "", // Use the status from filterPayload
      fromDate,
      toDate,
    };

    setLoading(true);

    try {
      const response = await fetch(
        `${config.baseUrl}api/Attendance/GetAttendanceDetail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        setAttendance(data.data);
      }
    } catch (error) {
      console.error("Error fetching department attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetDepartmentAttendance = async (filterPayload) => {
    const token = localStorage.getItem("authToken");

    // Send 0 if designationId or departmentId is null
    const requestBody = {
      departmentId: filterPayload?.departmentId || 0,
      designationId: filterPayload?.designationId || 0,
      search: "",
      fromDate: filterPayload?.fromDate,
      toDate: filterPayload?.toDate,
    };

    setLoading(true);

    try {
      const response = await fetch(
        `${config.baseUrl}api/Attendance/GetDepartmentAttendanceDetail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();

      if (data.status === 200) {
        setDeptAttendance(data.data.departments || []);
      }
    } catch (error) {
      console.error("Error fetching department attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  const AttendanceDetailAction = async (rowData) => {
    const token = localStorage.getItem("authToken");
    setLoading(true);

    try {
      setRowselect(rowData);
      setDialogDeatils(true);

      const requestBody = {
        employeeId: rowData.id,
        Date: rowData.date,
      };
      const response = await fetch(
        `${config.baseUrl}api/Attendance/GetEmployeeCheckInCheckoutDetail`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setAttendanceDetails(data);
    } catch (error) {
      console.error("Error fetching attendance details:", error);
    } finally {
      setLoading(false);
    }
  };

  const actionTemplate = (rowData) => (
    <div className="action-buttons flex">
      <Button
        className="p-button-rounded p-button-edit p-0 mr-3 action-images"
        onClick={() => AttendanceDetailAction(rowData)}
      >
        <img src={eyeIcon} alt="Eye" />
      </Button>
    </div>
  );

  const statusValue = [
    { name: "Present", value: "Present" },
    { name: "Leave", value: "Leave" },
    { name: "Early", value: "Early" },
    { name: "Late", value: "Late" },
  ];

  const handleFilterApply = () => {
    const filterPayload = {
      designationId: selectedDesignation || null,
      departmentId: selectedDepartment || null,
      status: selectedStatus || "",
      fromDate:
        employeeDateRange && employeeDateRange[0]
          ? formatDate(employeeDateRange[0])
          : null,
      toDate:
        employeeDateRange && employeeDateRange[1]
          ? formatDate(employeeDateRange[1])
          : null,
    };

    // Pass the filterPayload directly
    GetEmployeeAttendance(filterPayload);
  };

  const handleEmployeeDateRangeChange = (e) => {
    const selectedRange = e.value;
    setEmployeeDateRange(selectedRange);

    if (selectedRange && selectedRange[0] && selectedRange[1]) {
      // Updating the payload on date change
      const filterPayload = {
        designationId: selectedDesignation || null, // Use the value directly
        departmentId: selectedDepartment || null,
        fromDate: formatDate(selectedRange[0]),
        toDate: formatDate(selectedRange[1]),
      };

      GetEmployeeAttendance(filterPayload);

      GetDepartmentAttendance(filterPayload);
    }
  };

  const handleDepartmentDateRangeChange = (e) => {
    const selectedRange = e.value;
    setDepartmentDateRange(selectedRange);

    if (selectedRange && selectedRange[0] && selectedRange[1]) {
      // Creating filter payload including department and designation
      const filterPayload = {
        designationId: selectedDesignation || 0, // Use the value directly
        departmentId: selectedDepartment || 0,
        fromDate: formatDate(selectedRange[0]),
        toDate: formatDate(selectedRange[1]),
      };

      // Pass the entire filterPayload to the GetDepartmentAttendance function
      GetDepartmentAttendance(filterPayload);
    }
  };

  const handleTabChange = (event) => {
    const tabIndex = event.index;
    setActiveTabIndex(tabIndex);

    if (tabIndex === 0) {
      if (employeeDateRange && employeeDateRange[0] && employeeDateRange[1]) {
        const fromDate = formatDate(employeeDateRange[0]);
        const toDate = formatDate(employeeDateRange[1]);
        GetEmployeeAttendance(fromDate, toDate);
      } else {
        const { fromDate, toDate } = getDefaultDateRange();
        GetEmployeeAttendance(fromDate, toDate);
      }
    } else if (tabIndex === 1) {
      setDepartmentDateRange(null);

      const { fromDate, toDate } = getDefaultDateRange();
      GetDepartmentAttendance(fromDate, toDate);
    }
  };

  useEffect(() => {
    const { fromDate, toDate } =
      employeeDateRange && employeeDateRange[0] && employeeDateRange[1]
        ? {
            fromDate: formatDate(employeeDateRange[0]),
            toDate: formatDate(employeeDateRange[1]),
          }
        : getDefaultDateRange();

    // Prepare the filter payload, assuming default values for filters
    const filterPayload = {
      designationId: selectedDesignation ? selectedDesignation.id : null,
      departmentId: selectedDepartment ? selectedDepartment.id : null,
      fromDate,
      toDate,
    };

    // Call the API with the complete filter payload
    GetEmployeeAttendance(filterPayload);
  }, []);

  const deptAttendanceColumns = [
    { field: "departmentName", header: "Department Name" },
    {
      field: "totalEmployees",
      header: "Total Employees",
      body: (rowData) => (
        <span style={{ fontWeight: "600" }}>{rowData.totalEmployees}</span>
      ),
    },
    {
      field: "presentCount",
      header: "Present ",
      body: (rowData) => (
        <span style={{ color: "green", fontWeight: "600" }}>
          {rowData.presentCount}
        </span>
      ),
    },

    {
      field: "leaveCount",
      header: "Leave ",
      body: (rowData) => (
        <span style={{ color: "blue", fontWeight: "600" }}>
          {rowData.leaveCount}
        </span>
      ),
    },
    {
      field: "absentCount",
      header: "Absent ",
      body: (rowData) => (
        <span style={{ color: "red", fontWeight: "600" }}>
          {rowData.absentCount}
        </span>
      ),
    },
    {
      field: "lateArrivalCount",
      header: "Late Arrival ",
      body: (rowData) => (
        <span style={{ color: "#F59E0B", fontWeight: "600" }}>
          {rowData.lateArrivalCount}
        </span>
      ),
    },
    {
      field: "earlyArrivalCount",
      header: "Early Arrival ",
      body: (rowData) => (
        <span style={{ color: "#9B59B6", fontWeight: "600" }}>
          {rowData.earlyArrivalCount}
        </span>
      ),
    },
  ];

  const attendanceColumns = [
    { field: "employeeNumber", header: "EMP NO" },
    { field: "name", header: "NAME" },
    { field: "cnic", header: "CNIC" },
    {
      field: "currentPost",
      header: "Designation",
      body: (rowData) => (rowData.currentPost ? rowData.currentPost : "N/A"),
    },
    {
      field: "shift",
      header: "SHIFT",
      body: (rowData) => (rowData.shift ? rowData.shift : "N/A"),
    },
    { field: "date", header: "date" },
    {
      field: "checkIn",
      header: "CHECK-IN",
      body: (rowData) => (rowData.checkIn ? rowData.checkIn : "--"),
    },
    {
      field: "checkOut",
      header: "CHECK-OUT",
      body: (rowData) => (rowData.checkOut ? rowData.checkOut : "--"),
    },
    {
      field: "status",
      header: "STATUS",
      body: (rowData) => {
        const status = rowData.status;
        if (status === "Present") {
          return <Tag className="custom-success-tag" value="Present" />;
        } else if (status === "Late") {
          return <Tag className="custom-warning-tag" value="Late" />;
        } else if (status === "Leave") {
          return <Tag className="custom-danger-tag" value="Leave" />;
        } else if (status === "Early") {
          return <Tag className="custom-info-tag" value="Early" />;
        } else {
          return <Tag className="custom-info-tag" value={status} />;
        }
      },
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const fetchFilters = async () => {
    try {
      const token = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers,
      };

      const [departmentResponse, designationResponse] = await Promise.all([
        fetch(`${config.baseUrl}api/Department/GetAll`, options),
        fetch(`${config.baseUrl}api/Posts/GetAll`, options),
      ]);

      if (!departmentResponse.ok || !designationResponse.ok) {
        throw new Error("Error fetching filter data");
      }
      const departmentsData = await departmentResponse.json();
      const filteredDep = departmentsData?.data.map((department) => ({
        value: department.id,
        label: department.name,
      }));
      setDepartments(filteredDep);

      const designationsData = await designationResponse.json();

      setDesignations(designationsData?.data);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const menuItems = [
    {
      items: [
        {
          template: () => (
            <div className="p-field p-fluid filters-label">
              <p>Filters</p>
              <i
                className="pi pi-times"
                onClick={() => {
                  setSelectedVertical(null);
                  setSelectedDepartment(null);
                  setSelectedDesignation(null);
                  setSelectedStatus(null);
                }}
              ></i>
            </div>
          ),
        },
        {
          label: "Department",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedDepartment}
                options={departments}
                onChange={(e) => setSelectedDepartment(e.value)}
                placeholder="Select Department"
                appendTo="self"
                loading={loading}
              />
            </div>
          ),
        },
        {
          label: "Designation",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedDesignation}
                options={designations}
                onChange={(e) => setSelectedDesignation(e.value)}
                optionLabel="name"
                placeholder="Select Designation"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Status",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedStatus}
                options={statusValue}
                onChange={(e) => setSelectedStatus(e.value)}
                optionLabel="name"
                placeholder="Select Status"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Actions",
          template: () => (
            <div
              className="p-field p-fluid mt-4"
              style={{ display: "flex", gap: "10px" }}
            >
              <Button
                label="Cancel"
                className="p-button-secondary"
                onClick={(e) => {
                  setSelectedVertical(null);
                  setSelectedDepartment(null);
                  setSelectedStatus(null);
                  setSelectedDesignation(null);
                  menu.current.toggle(e);
                }}
              />
              <Button
                label="Apply Filter"
                className="p-button-primary"
                onClick={handleFilterApply}
              />
            </div>
          ),
        },
      ],
    },
  ];

  const onHide = () => {
    setShowDialog(false);
  };

  const handleViewAttendanceDateRangeChange = (e) =>
    setViewAttendanceDateRange(e.value);

  const consolidateAttendanceDetails = (checkInOutDetails) => {
    const consolidatedData = {};

    // Group by status
    checkInOutDetails.forEach((detail) => {
      if (!consolidatedData[detail.status]) {
        consolidatedData[detail.status] = [];
      }
      consolidatedData[detail.status].push({
        checkIn: detail.checkIn,
        checkOut: detail.checkOut,
      });
    });

    return consolidatedData;
  };

  const handleDetailsClick = (rowData) => {
    AttendanceDetailAction(rowData);
  };
  const rowClassName = "p-datatable-clickable";

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {isEdit ? "Edit Attendance" : "Add Attendance"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
        // style={{ width: "25vw" }}
      >
        <EditAddForm
          rowData={rowselect}
          GetEmployeeAttendance={GetEmployeeAttendance}
          setShowDialog={setShowDialog}
          editable={editable}
          onHide={onHide}
          formData={isEdit ? selectedData : null}
          setRes={setRes}
        />
      </Dialog>

      {dialogDetails && (
        <Dialog
          visible={dialogDetails}
          onHide={() => setDialogDeatils(false)}
          header={
            <div className="popup-header">
              <div className="popup-header-text">Attendance Details Log</div>
            </div>
          }
          className="dialog-size"
          style={{ width: "40vw" }}
        >
          {loading ? (
            <div className="spinner-overlay">
              <Spinner />
            </div>
          ) : (
            <div>
              {attendanceDetails &&
              attendanceDetails.checkInOutDetails &&
              attendanceDetails.checkInOutDetails.length > 0 ? (
                <div>
                  <div>
                    <p className="mb-1">
                      <strong>Employee Name:</strong>{" "}
                      {attendanceDetails.employeeName}
                    </p>
                    <p className="mb-1">
                      <strong>Date:</strong> {attendanceDetails.date}
                    </p>
                  </div>
                  {Object.entries(
                    consolidateAttendanceDetails(
                      attendanceDetails.checkInOutDetails
                    )
                  ).map(([status, logs]) => (
                    <div key={status}>
                      <h6 style={{ fontWeight: "600", margin: "10px 0" }}>
                        <Tag
                          className={
                            status === "Present"
                              ? "custom-success-tag"
                              : status === "Late"
                              ? "custom-warning-tag"
                              : status === "Leave"
                              ? "custom-danger-tag"
                              : "custom-info-tag"
                          }
                          value={status}
                        />
                      </h6>
                      <DataTable
                        value={logs}
                        paginator
                        rows={5}
                        responsiveLayout="scroll"
                      >
                        <Column
                          field="checkIn"
                          header="Check-In"
                          body={(rowData) => rowData.checkIn}
                        />

                        <Column
                          field="checkOut"
                          header="Check-Out"
                          body={(rowData) => rowData.checkOut}
                        />
                      </DataTable>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No data found.</p>
              )}
            </div>
          )}
        </Dialog>
      )}

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-4">
          <h5 className="pages-internal-heading">Attendance Management</h5>
        </div>

        <div className="col-12 md:col-8 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Calendar
            value={
              activeTabIndex === 0
                ? employeeDateRange
                : activeTabIndex === 1
                ? departmentDateRange
                : viewAttendanceDateRange
            }
            onChange={
              activeTabIndex === 0
                ? handleEmployeeDateRangeChange
                : activeTabIndex === 1
                ? handleDepartmentDateRangeChange
                : handleViewAttendanceDateRangeChange
            }
            selectionMode="range"
            placeholder="Select Date Range"
            showIcon
            className="ml-3"
          />
          <Menu model={menuItems} popup ref={menu} className="filter-menu" />
          {activeTabIndex !== 2 && (
            <Button
              label="Filters"
              icon="pi pi-sliders-h"
              className="p-button ml-3 filters-btn"
              onClick={(e) => menu.current.toggle(e)}
            />
          )}
          {activeTabIndex === 0 && (
            <Button
              label="Add Employee's Attendance"
              icon="pi pi-plus"
              onClick={() => {
                setEditable(false);
                setShowDialog(true);
              }}
              className="p-button ml-3 mt-2"
            />
          )}
        </div>
      </div>

      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}

      <div className="card">
        <TabView
          activeIndex={activeTabIndex}
          onTabChange={handleTabChange}
          className="custom-tabview"
        >
          <TabPanel header="Employee’s Attendance">
            <DataTable
              emptyMessage="No record found."
              value={attendance}
              className="custom-data-table"
              globalFilter={globalFilter}
              paginator
              rows={10}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              onRowClick={(event) => handleDetailsClick(event.data)}
              rowClassName={rowClassName}
            >
              {attendanceColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={data.body}
                />
              ))}
            </DataTable>
          </TabPanel>

          <TabPanel header="Department Attendance">
            <DataTable
              value={deptAttendance}
              emptyMessage="No record found."
              className="custom-data-table"
              paginator
              globalFilter={globalFilter}
              rows={10}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              {deptAttendanceColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={data.body}
                />
              ))}
            </DataTable>
          </TabPanel>

          <TabPanel header="View Attendance">
            <ViewAttendanceScreen
              dateRange={viewAttendanceDateRange}
              globalFilter={globalFilter}
            />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

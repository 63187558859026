import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/assests/edit.png";
import deleteIcon from "../../../../assets/assests/delete.png";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import EditAddForm from "./edit-add-form";
import config from "../../../../config";
import Axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../../components/spinner";
import { confirmDialog } from "primereact/confirmdialog";

export default function ShiftScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [editable, setEditable] = useState(false);
  const [assignCheck, setAssignCheck] = useState(false);

  const GetShiftData = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(`${config.baseUrl}api/Shift/GetAllShifts`, {
        method: "Get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(filters),
      });

      const data = await response.json();
      setShiftList(data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetShiftData();
  }, []);

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const assignUserTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        <p
          onClick={() => {
            toggleDialogMode(true);
            setSelectedData(rowData);
            setEditable(true);
            setAssignCheck(true);
          }}
          style={{ fontWeight: "600" }}
          className="assign-para"
        >
          <i
            className="pi pi-plus"
            style={{
              marginRight: "0.5rem",
              fontSize: "8px",
              fontWeight: "600",
            }}
          ></i>
          Assign User
        </p>
      </div>
    );
  };

  const confirmDelete = (deleteId) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        deleteAction(deleteId);
      },
    });
  };

  const deleteAction = async (deleteId) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);

      const response = await Axios.delete(
        `${config.baseUrl}api/Shift/DeleteShift/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Fetch updated shift data after deletion
      GetShiftData();
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error("Error deleting shift:", error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false); // Ensure loading state is reset even on error
      setShowDialog(false); // Close any open dialogs if needed
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        <Button
          className="p-button-edit p-0 mr-3"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedData(rowData);
            setEditable(true);
            setAssignCheck(false);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>

        {/* <Button
          className="p-button-rounded p-button-delete p-0"
          aria-label="Delete"
          type="button"
          onClick={() => deleteAction(rowData)} // Pass rowData here
        >
          <img src={deleteIcon} alt="Delete" />
        </Button> */}

        <Button
          className="p-button-rounded p-button-edit p-0 mr-3 action-images"
          onClick={() => confirmDelete(rowData.id)} // Trigger confirmation dialog
          aria-label="delete"
        >
          <img src={deleteIcon} alt="Eye" />
        </Button>
      </div>
    );
  };

  const columnsData = [
    // {
    //   field: "shiftId",
    //   header: "Shift ID",
    // },
    {
      field: "shiftName",
      header: "Shift Name",
    },
    {
      field: "shiftIncharge",
      header: "Shift Incharge",
    },
    {
      field: "startingTime",
      header: "Starting Time",
    },
    {
      field: "endingTime",
      header: "Ending Time",
    },
    {
      field: "hours",
      header: "Working Hours",
      // body: (rowData) => workingHours(rowData),
    },
    {
      field: "shiftDays",
      header: "Shift Days",
    },
    {
      body: assignUserTemplate,
      header: "Assign User",
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const handleAddNewClick = () => {
    setEditable(false); // Set isEdit to false for "Add New"
    toggleDialogMode(false); // Show dialog in "Add" mode
    setAssignCheck(false);
  };

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {!assignCheck
                ? isEdit
                  ? "Edit Shift"
                  : "Add Shift"
                : "Assign User"}
            </div>
          </div>
        }
        style={{ minWidth: "50vw" }}
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          assignCheck={assignCheck}
          GetShiftData={GetShiftData}
          onHide={onHide}
          editable={editable}
          setShowDialog={setShowDialog}
          rowData={isEdit ? selectedData : null}
        />
      </Dialog>
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Shift Management</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Button
            label="Add New"
            icon="pi pi-plus"
            onClick={handleAddNewClick}
            className="p-button ml-3"
          />
        </div>
      </div>
      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={shiftList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          globalFilter={globalFilter}
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}

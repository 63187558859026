import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dialog } from "primereact/dialog";
import logo from "../../../../assets/assests/logo.png";
import google from "../../../../assets/assests/google.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../../../config";
import OtpScreen from "./otpscreen";

export default function LoginScreen({ isLoggedIn, handleLogin }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [userName, setUserName] = useState("");
  const [otpFromLogin, setOtpFromLogin] = useState(null);
  const [otpExpiryFromLogin, setOtpExpiryFromLogin] = useState(null);
  
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required."),
      userName: Yup.string().required("User Name is required."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${config.baseUrl}api/Account/PortalLogin`,
          values,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.data?.data?.otp) {
          setUserName(values.userName);
          setOtpFromLogin(response?.data?.data.otp);
          setOtpExpiryFromLogin(response?.data?.data.otpExpiry)
          setShowDialog(true); // Show OTP dialog
          toast.success("Please enter OTP.");
        } else {
          toast.error(response.data.message || "Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        toast.error(
          error.response?.data?.message ||
            "Login failed due to a network error. Please try again."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const onHide = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">OTP VERIFICATION</div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <OtpScreen
          userName={userName}
          onHide={onHide}
          otpFromLogin={otpFromLogin}
          otpExpiryFromLogin={otpExpiryFromLogin}
          loading={loading}
          handleLogin={handleLogin}
        />
      </Dialog>
      <div className="auth-page">
        <div className="container">
          <div className="content-wrapper">
            <div className="sign-in-box">
              <h2>Sign in</h2>
              <p className="create-account">
                {/* New user? <a href="#">Create an account</a> */}
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="input-group">
                  <FloatLabel>
                    <InputText
                      id="email"
                      name="userName"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="email">User Name</label>
                  </FloatLabel>
                  {formik.touched.userName && formik.errors.userName ? (
                    <div className="error">{formik.errors.userName}</div>
                  ) : null}
                </div>
                <div className="input-group">
                  <FloatLabel>
                    <Password
                      feedback={false}
                      inputId="password"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      toggleMask
                    />
                    <label htmlFor="password">Password</label>
                  </FloatLabel>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>

                {/* <a href="#">Can't sign in?</a> */}
                <Button
                  type="submit"
                  className="p-button sign-in-button"
                  loading={loading}
                  disabled={loading}
                  label={"Sign in"}
                />

                {/* <div className="divider">
                  <span></span>
                  <p>or</p>
                  <span></span>
                </div> */}
                {/* <button type="button" className="google-sign-in">
                  <img src={google} alt="Google icon" /> Google
                </button> */}
                <p className="terms">
                  Protected by reCAPTCHA and subject to the{" "}
                  <a href="#">ET & NC Policy</a> and{" "}
                  <a href="#">Terms of Service</a>.
                </p>
              </form>
            </div>
            <div className="info-box">
              <img src={logo} alt="Zindigi logo" className="logo" />
              <h1>Optimize Workforce Management with Js Zindigi</h1>
              <p>
                Designed to streamline employee management, these tools offer
                seamless integration for tracking attendance, managing leaves,
                and analyzing HR data. Our platform ensures effortless
                administrative tasks and improved workforce productivity, all
                within a flexible and scalable cloud-based environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

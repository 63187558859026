import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/assests/edit.png";
import deleteIcon from "../../../../assets/assests/delete.png";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import EditAddForm from "./edit-add-form";
import config from "../../../../config";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../components/spinner";
export default function Department() {
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [leaveRequestList, setLeaveRequestList] = useState();
  const [res, setRes] = useState();
  const [rowselect, setRowselect] = useState(null);
  const [editable, setEditable] = useState();
  const [loading, setLoading] = useState(false);
  const GetLeaveRequest = async () => {
    const token = localStorage.getItem("authToken");
    setLoading(true);
    try {
      const response = await fetch(` ${config.baseUrl}api/Department/GetAll`, {
        method: "Get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setLeaveRequestList(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setShowDialog(true);
  };
  useEffect(() => {
    GetLeaveRequest();
  }, [res]);
  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const confirmDelete = (deleteId) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        deleteAction(deleteId);
      },
    });
  };

  const deleteAction = async (deleteId) => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.delete(
        `${config.baseUrl}api/Department/DeleteDepartment/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      GetLeaveRequest();
      toast.success("Deleted Successfully");
      setShowDialog(false);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        <Button
          className=" p-button-edit p-0 mr-3"
          onClick={() => {
            toggleDialogMode(true);
            editAction(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button
          className="p-button-rounded p-button-edit p-0 mr-3 action-images"
          onClick={() => confirmDelete(rowData.id)} // Trigger confirmation dialog
          aria-label="delete"
        >
          <img src={deleteIcon} alt="Eye" />
        </Button>
      </div>
    );
  };

  const handleDetailsClick = (rowData) => {
    console.log("rowData111111", rowData);
    navigate(`/leave/details/${rowData.employeeId}`, {
      state: { employee: rowData },
    });
    console.log("employee", rowData);
  };
  const rowClassName = "p-datatable-clickable";

  const columnsData = [
    {
      field: "name",
      header: "Name",
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {isEdit ? "Edit Department" : "Add Department"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          rowData={rowselect}
          setShowDialog={setShowDialog}
          editable={editable}
          onHide={onHide}
          formData={isEdit ? selectedData : null}
          setRes={setRes}
        />
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Department Management</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          {/* <Calendar
              id="date"
              placeholder="Calender"
              showIcon
              className="ml-3 "
            /> */}

          <Button
            label="Add Department"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setShowDialog(true);
            }}
            className="p-button ml-3 mt-2"
          />
        </div>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}
      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={leaveRequestList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          globalFilter={globalFilter}
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}

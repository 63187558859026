import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth_slice/login_user_slice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import config from "../../../config";
import imageIcon from "../../../assets/assests/user-icon.png";

export const AppTopbar = (props) => {
  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signoutConfirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      icon: "pi pi-exclamation-triangle",
      header: "Confirmation",
      acceptClassName: "p-button p-button p-button-sm width-80",
      accept: () => {
        dispatch(logout());
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      },
      reject: () => {},
    });
  };

  const handleNavigate = (event) => {
    event.preventDefault();
    navigate({
      pathname: "/settings",
    });
  };

  const imageUrl = `${config.baseUrl}/${user?.data?.profile}`;

  return (
    <>
      <ConfirmDialog />

      <div className="layout-topbar">
        <div className="layout-topbar-ellipsis">
          <button
            type="button"
            className="p-link  layout-menu-button layout-topbar-button"
            onClick={props.onToggleMenuClick}
          >
            <i className="pi pi-bars" />
          </button>
          {props.currentRouteLabel && (
            <div className="layout-topbar-heading">
              {props.currentRouteLabel}
            </div>
          )}
        </div>

        <div className="layout-topbar-user">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle d-flex align-items-center"
              style={{ gap: "10px" }}
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="profile-container">
                <img
                  src={!imageUrl ? imageUrl : imageIcon}
                  alt="Profile"
                  className="profile-image"
                />
                <div className="status-dot"></div>
              </div>
              Hello, &nbsp; {user ? user?.user?.name : "Admin"} &nbsp;
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {/* <li>
                <a className="dropdown-item" onClick={handleNavigate}>
                  Settings
                </a>
              </li> */}
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    signoutConfirm();
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import config from "../../../../config";
import { useNavigate } from "react-router-dom";
import "../../../../styles/login.scss";
import { Button } from "primereact/button";
import moment from "moment";

export default function OtpScreen({
  userName,
  onHide,
  otpFromLogin,
  handleLogin,
  otpExpiryFromLogin,
}) {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    if (otpFromLogin) {
      const otpDigits = otpFromLogin
        .toString()
        .split("")
        .map((digit) => digit);
      setOtp(otpDigits);
    }

    // Set the timer based on otpExpiryFromLogin
    if (otpExpiryFromLogin) {
      const expiryTime = moment(otpExpiryFromLogin);
      const now = moment();
      const remainingTime = expiryTime.diff(now, "seconds"); // Calculate remaining time in seconds
      setTimeRemaining(remainingTime > 0 ? remainingTime : 0);
    }
  }, [otpFromLogin, otpExpiryFromLogin]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          toast.error("OTP expired. Please request a new one.");
          onHide();
          return 0; // Stop the timer
        }
        return prev - 1; // Decrement time remaining
      });
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, []);

  const formatTimeRemaining = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    // Pad minutes and seconds with leading zeros
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleOtpSubmit = async () => {
    const controller = new AbortController();

    setLoading(true);
    const finalOtp = otp.join("");
    try {
      const response = await axios.post(
        `${config.baseUrl}api/Account/verify-otp`,
        { userName, otp: finalOtp },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data?.status === 200) {
        const token = response.data?.data?.accessToken;
        const userId = response.data?.data?.userDetails?.id;
        const role = response.data?.data?.userDetails?.role;
        if (token) localStorage.setItem("authToken", token);
        handleLogin(token);
        if (role) localStorage.setItem("role", role);
        if (userId) localStorage.setItem("userId", userId);
        toast.success("OTP verified successfully!");
        onHide();
        navigate("/");
      } else {
        toast.error(response.data.message || "OTP verification failed");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      toast.error("OTP verification failed. Please try again.");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
      <div className="flex flex-column text-center align-items-center">
        <div
          className="text-center"
          style={{ justifyContent: "center", gap: "10px" }}
        >
          <h3>Authentication</h3>
          <p>Please enter 4-digit OTP sent to your registered mobile number.</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "40px",
          }}
        >
          {otp.map((digit, index) => (
            <InputText
              key={index}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength={1}
              style={{ width: "50px", textAlign: "center" }}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        <br />
        <p className="otp-expiry">
          Time remaining: {formatTimeRemaining(timeRemaining)}
        </p>

        <Button
          onClick={handleOtpSubmit}
          className="otp-btn mt-4"
          label="Submit"
          loading={loading}
          disabled={loading}
        />
        <br />
      </div>
    </>
  );
}
